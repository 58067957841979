<template>
    <div class="flex">
        <div class="w-1/2">
            <PersonList :key="listKey" @clicked="onClickChild" @createNew="(addPerson = !addPerson), (personId = 0)" />
        </div>
        <div class="w-2" />
        <div class="w-1/2">
            <PersonDetails @reloadList="reloadList()" :person-id="personId" :add-person="addPerson" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeComponent: "PersonList",
            personId: 0,
            addPerson: false,
            listKey: 0,
        };
    },

    components: {
        PersonList: () => import(/* webpackChunkName: "PersonList" */ "./PersonList.vue"),
        PersonDetails: () => import(/* webpackChunkName: "PersonDetails" */ "./PersonDetails.vue"),
    },

    methods: {
        reloadList() {
            this.listKey += 1;
            this.addPerson = false;
        },

        onClickChild(value) {
            this.addPerson = false;
            this.personId = value.id;
            this.scrollUp();
        },

        scrollUp() {
            window.scrollTo({ top: window.innerHeight / 3, behavior: "smooth" });
        },
    },
};
</script>
